<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    forLabel: {
      type: String,
      default: "",
    },
    isRequiredIcon: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
<template>
  <label :for="forLabel" :class="classes">
    {{ title }}
    <span v-show="isRequiredIcon" class="text-danger">*</span>
  </label>
</template>
