<template>
  <b-modal
    @hidden="$emit('toggleModal', false)"
    ref="modal"
    centered
    size="lg"
    :title="$t('Update Tickets')"
    :hide-footer="true"
    :hide-header="false"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <b-row>
      <b-col cols="6">
        <div class="form-group mx-auto">
          <ElLabel
            for-label="ContactType"
            :title="$t('Status')"
            :is-required-icon="true"
          />
          <select
            class="form-control"
            v-model="form.status"
            :disabled="submitted"
          >
            <option value="new">
              {{ $t("New") }}
            </option>
            <option value="open">
              {{ $t("Open") }}
            </option>
            <option value="waiting-for-response">
              {{ $t("Waiting For Response") }}
            </option>
            <option value="pending">
              {{ $t("Pending") }}
            </option>
            <option value="resolved">
              {{ $t("Resolved") }}
            </option>
          </select>
        </div>
      </b-col>
      <!-- <b-col cols="6">
                <div class="form-group mx-auto">
                    <ElLabel
                        for-label="ContactType"
                        :title="$t('Delete')"
                        :is-required-icon="true"
                    />
                    <select
                        class="form-control"
                        v-model="form.delete"
                        :disabled="submitted"
                    >
                        <option :value="true">
                            {{ $t("Yes") }}
                        </option>
                        <option :value="false">
                            {{ $t("No") }}
                        </option>
                    </select>
                </div>
            </b-col> -->
    </b-row>
    <div class="d-flex align-items-center justify-content-end mt-2">
      <button
        @click="$emit('toggleModal', false)"
        class="btn btn-secondary me-2"
      >
        {{ $t("Cancel") }}
      </button>
      <button @click="submit" class="btn btn-primary">
        {{ $t("Submit") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ElLabel from "../ElLabel.vue";
export default {
  emits: ["toggleModal", "listUpdate"],
  components: {
    ElLabel,
  },
  props: {
    selectedTicketIds: {
      type: Array,
      default: [],
    },
    toggleModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("tickets", ["tickets"]),

    filteredTickets() {
      return this.tickets?.filter(
        (ticket) => !this.selectedTicketIds.includes(ticket.id)
      );
    },
  },
  data() {
    return {
      submitted: false,
      form: {
        status: "new",
      },
    };
  },
  watch: {
    toggleModal(val) {
      try {
        if (!!val) {
          this.$refs.modal.show();
          return;
        }
        this.$refs.modal.hide();
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    if (!this.tickets?.length && this.toggleModal) {
      this.$store.dispatch("tickets/list", {
        params: {
          page: 1,
          perPage: 100,
          sortOrder: "desc",
          sortBy: "id",
        },
      });
    }
  },
  methods: {
    async submit() {
      this.submitted = true;
      console.log("form", { ...this.form, ticketIds: this.selectedTicketIds });
      try {
        let response = await this.$store.dispatch("tickets/bulkEdit", {
          ...this.form,
          ticketIds: this.selectedTicketIds,
        });
        this.$emit("toggleModal", false);
        this.$emit("listUpdate", true);
        this.form = {
          status: "new",
          parentId: null,
          ticketTypeId: [],
          delete: false,
        };
      } catch (e) {
        console.error(e);
      } finally {
        this.submitted = false;
      }
    },
  },
};
</script>

<style scoped>
/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
  cursor: pointer;
}

.editor-text {
  font-size: 1.1rem;
}

i {
  font-size: 2rem;
  color: #194966;
}
</style>
